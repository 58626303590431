import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEnvelopeSquare } from "@fortawesome/free-solid-svg-icons";
import { faLinkedin, faGithubSquare, faMedium } from "@fortawesome/free-brands-svg-icons";

import '../css/Base.css';
import '../css/Contact.css';

import SectionHeader from '../components/SectionHeader.js';

library.add(faLinkedin, faGithubSquare, faMedium, faEnvelopeSquare);

export default class Contact extends React.Component {
  render() {
    return (
      <div id="contact">
        <SectionHeader sectionTitle="Keep In Touch" />
        <div className="contact-body">
          <div className="social-media-icons">
            <a href="https://www.linkedin.com/in/lindsayzhou/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={["fab", "linkedin"]} className="social-media-icon" />
            </a>
            <a href="https://github.com/lin-zhou" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={["fab", "github-square"]} className="social-media-icon" />
            </a>
            <a href="https://lindsayzhou.medium.com/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={["fab", "medium"]} className="social-media-icon" />
            </a>
            <a href="mailto: zhou.lindsay@yahoo.com">
              <FontAwesomeIcon icon="envelope-square" className="social-media-icon" />
            </a>
          </div>
          <div className="calendly-signup">
            Want to chat?&nbsp;
            <b><a className="contact-link" target="_blank" href="https://calendly.com/lindsay-zhou/coffee-chat" rel="noopener noreferrer">
              Book a time on Calendly
            </a>!</b>
          </div>
        </div>
      </div>
    )
  }
}