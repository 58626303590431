import React from 'react';

import '../css/Base.css';
import '../css/components/Sidenav.css';

import { Slide, IconButton } from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';

import Grayout from './Grayout.js';
import SidenavContent from './SidenavContent.js';

export default class Sidenav extends React.Component {

  constructor(props) {
    super(props);

    this.closeMenu = this.closeMenu.bind(this);

    this.state = {
      isMenuOpen: false
    };
  }

  openMenu() {
    document.getElementById("grayout").style.opacity = 0.3;
    this.setState({isMenuOpen: true});
  }

  closeMenu() {
    document.getElementById("grayout").style.opacity = 0;
    this.setState({isMenuOpen: false});
  }

  render () {
    return (
      <div id="sidenav">
      <Grayout isGrayedOut={this.state.isMenuOpen} />
      <Slide direction="right" in={this.state.isMenuOpen} mountOnEnter unmountOnExit>
        <SidenavContent closeMenu={this.closeMenu}/>
      </Slide>
      <div>
      <IconButton id="open-menu-btn" onClick={() => this.openMenu()}>
        <MenuIcon style={{fontSize: 35, fill: "black"}}/>
      </IconButton>
      </div>
    </div>
    )
  }
}