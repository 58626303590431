import React from 'react';
import { Chrono } from "react-chrono";

import '../css/Base.css';
import '../css/Timeline.css';

import SectionHeader from '../components/SectionHeader.js';

import * as json from '../data/timeline-data.json';
const items = json.default;

export default class Timeline extends React.Component {
  render() {
    return (
      <div id="timeline">
        <SectionHeader sectionTitle="Timeline" />
        <div id="timeline-container">
          <div id="timeline-style">
            <Chrono
              items={items}
              mode="VERTICAL_ALTERNATING"
              scrollable
              theme={{ primary: "#E23F1C", secondary: "white" }}
            />
          </div>
        </div>
      </div>
    )
  }
}