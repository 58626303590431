import React from 'react';

import '../css/Base.css';
import '../css/components/Sidenav.css';

import { Link } from 'react-scroll';
import { List, ListItem } from '@material-ui/core';

export default class SidenavContent extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      items: [
        { name: 'about', label: 'About Me' },
        { name: 'timeline', label: 'Timeline' },
        { name: 'blog', label: 'Blog' },
        { name: 'contact', label: 'Contact' }
      ]
    };

    this.wrapperRef = React.createRef();
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(e) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(e.target)) {
      this.props.closeMenu();
    }
  }

  render() {
    return (
      <div ref={this.wrapperRef} id="sidenav-content">
        <div id="name-icon">L &lt;&gt; Z</div>
        <List disablePadding dense className="sidenav-list">
          {this.state.items.map(({ label, name, ...rest }) => (
            <Link
              className="sidenav-button-wrapper"
              to={name}
              key={name}
              smooth={true}
              offset={0}
              duration={500}
              onClick={this.props.closeMenu}
            >
              <ListItem className="sidenav-button" style={{ height: 50 }} button {...rest}>
                <div className="sidenav-button-text">{label}</div>
              </ListItem>
            </Link>
          ))}
        </List>
      </div>
    )
  }
}