import React, { useState, useEffect } from "react";

import '../css/Base.css';
import '../css/Blog.css';

import SectionHeader from '../components/SectionHeader.js';
import BlogCard from '../components/BlogCard.js';

const Blog = () => {
  const [mediumData, setMediumData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch(`https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@lindsayzhou`)
      .then(res => res.json())
      .then(response => {
        setMediumData(response.items);
        setIsLoading(false);
      })
      .catch(err => console.log(err));
  }, []);

  const finalData = mediumData.filter(article => article.categories.length > 0).slice(0, 6);

  return (
    <div id="blog">
      <SectionHeader sectionTitle="Blog" />
      {isLoading && <p>Fetching data from Medium!</p>}
      <div className="section-container">
        <p>Check out my blogs on <a href="https://lindsayzhou.medium.com/" target="_blank" rel="noopener noreferrer" className="about-link">Medium</a>,
          or take a look at my publication,&nbsp;
          <a href="https://medium.com/swe-tea" target="_blank" rel="noopener noreferrer" className="about-link">SWE Tea</a>,
          which is open to new writers. If you're interested in writing for SWE Tea, fill out&nbsp;
          <a href="https://forms.gle/aMGW4Cz3qzT7Q5n2A" target="_blank" rel="noopener noreferrer" className="about-link">this form</a>,
          and I'll get back to you soon.
        </p>
        <div id="blog-container">
          {finalData.map(article => (
            <BlogCard article={article} />
          ))}
        </div>
      </div>

    </div>
  );
};

export default Blog;