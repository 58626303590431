import React from 'react';

import '../css/Blog.css';

export default function BlogCard(props) {
  return <div className="blog-card">
    <img className="blog-image" src={props.article.thumbnail} alt="article thumbnail" />
    <div className="blog-content">
      <a href={props.article.link} target="_blank" rel="noopener noreferrer" className="blog-title">{props.article.title}</a>
      <div className="blog-info">
        <a href="https://lindsayzhou.medium.com/" target="_blank" rel="noopener noreferrer" className="blog-author about-link">
          Lindsay Zhou
        </a>&nbsp;&lt;&gt;&nbsp;
      <div className="blog-date" >{new Date(props.article.pubDate).toLocaleDateString("en-US", options)}</div>
      </div>
    </div>
  </div>;
}

var options = { year: 'numeric', month: 'long', day: 'numeric' };