import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEnvelopeSquare } from "@fortawesome/free-solid-svg-icons";
import { faLinkedin, faGithubSquare } from "@fortawesome/free-brands-svg-icons";

import '../css/Base.css';
import '../css/About.css';
import '../assets/fonts/webfontkit/fonts.css';

import resume from '../data/Zhou_Lindsay.pdf';

library.add(faLinkedin, faGithubSquare, faEnvelopeSquare);

export default class About extends React.Component {
  render() {
    return (
      <div id="about">
        <div id="about-frontpage">
          <div className="about-title">
            <div className="about-text-container">
              <div className="name">
                LINDSAY ZHOU
              </div>
              <div className="tagline">
                BUILDING FROM AIR AND IDEAS
              </div>
            </div>
          </div>
        </div>
        <div className="about-blurb section-container">
          <div className="about-blurb__written">
            <h2 className="about-blurb__written__title">
              A CREATIVE AT HEART
            </h2>
            <div className="social-media-icons about-icons">
              <a href="https://www.linkedin.com/in/lindsayzhou/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={["fab", "linkedin"]} className="about-icon" />
              </a>
              <a href="https://github.com/lin-zhou" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={["fab", "github-square"]} className="about-icon" />
              </a>
              <a href="https://lindsayzhou.medium.com/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={["fab", "medium"]} className="about-icon" />
              </a>
              <a href="mailto: zhou.lindsay@yahoo.com">
                <FontAwesomeIcon icon="envelope-square" className="about-icon" />
              </a>
            </div>
            <div className="resume-download">
              <a className="resume-download__button" href={resume} download="Zhou_Lindsay.pdf">Download Resume</a>
            </div>
            <div className="about-blurb__written__text">
              <br/>
              Tech enthusiast. Hobbyist writer. Lover of languages. Building cool tech, art, and worlds from nothing but air and ideas.
              (Ok, and a laptop.)<br/>
              <br/>

              Hey, I'm Lindsay! I'm a computer science and linguistics double major at UNC-Chapel Hill who
              explores creative applications of technology to improve the human experience and drive positive change.<br/>
              <br/>

              𝗠𝘆 𝗣𝗮𝘀𝘀𝗶𝗼𝗻𝘀:<br/>
              ► Uplifting underrepresented groups in tech<br/>
              ► Creating a welcoming environment for people from all walks of life to participate in tech<br/>
              ► Advocating for balance and happiness while working in tech<br/>
              <br/>
              
              Even though I'm on the software engineering track, I've always had a touch of the humanities at heart! When
              I'm not programming or doing other tech-related things, you can catch me working on my fantasy novel,
              writing blogs, constructing languages, reading, playing Nintendo games, or gooning with friends!<br/>
              <br/>

              𝗖𝗼𝗻𝗻𝗲𝗰𝘁 𝘄𝗶𝘁𝗵 𝗺𝗲 𝗮𝗻𝗱 𝗹𝗲𝘁'𝘀 𝘁𝗮𝗹𝗸 ✨
            </div>
          </div>
        </div>
      </div>
    )
  }
}