import React from 'react';
import { Link } from 'react-scroll';

import '../css/components/Navbar.css';

import Sidenav from '../components/Sidenav.js';

export default class Navbar extends React.Component {
  render() {
    return (
      <nav className="nav" id="navbar">
        <Sidenav />
        <div id="navbar-without-sidenav">
          <div className="text-logo">周林茜</div>
          <div className="nav-content">
            <ul className="nav-items">
              <Link
                className="nav-item"
                activeClass="active"
                to="about"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
              >
                About Me
              </Link>
              <Link
                className="nav-item"
                activeClass="active"
                to="timeline"
                spy={true}
                smooth={true}
                duration={500}
              >
                Timeline
              </Link>
              <Link
                className="nav-item"
                activeClass="active"
                to="blog"
                spy={true}
                smooth={true}
                duration={500}
              >
                Blog
              </Link>
              <Link
                className="nav-item"
                activeClass="active"
                to="contact"
                spy={true}
                smooth={true}
                duration={500}
              >
                Contact
              </Link>
            </ul>
          </div>
        </div>
      </nav>
    )
  }
}