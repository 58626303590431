import React from 'react';
import '../css/Base.css';
import '../css/components/SectionHeader.css';
import '../assets/fonts/webfontkit/fonts.css';

export default function SectionHeader(props) {
  return(
    <h1 className="section-header">
      { props.sectionTitle }
    </h1>
  )
}