import React from 'react';

import '../css/components/Grayout.css';

export default function Grayout(props) {
  let grayout;
  if (props.isGrayedOut) {
    grayout = <div id="grayout" className="grayout-active" />
  } else {
    grayout = <div id="grayout" className="grayout-inactive" />
  }

  return(grayout);
}