import React from 'react';

import {
  BrowserRouter as Router,
  Route
} from "react-router-dom";

import './css/Base.css';
import './css/App.css';

import Navbar from './components/Navbar.js';
import About from './views/About.js';
import Timeline from './views/Timeline.js';
import Blog from './views/Blog.js';
import Contact from './views/Contact.js';

function App() {
  return (
    <div className="App">
      <Router>
        <Route path="/">
          <Navbar />
          <About />
          <Timeline />
          <Blog />
          <Contact />
        </Route>
      </Router>
    </div>
  );
}

export default App;
